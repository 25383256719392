import { Outlet, Link } from "react-router-dom";

const Layout = (props) => {
  const cus = {
    padding: "15px",
    margin: "0",
  };

  let logged = parseInt(localStorage.getItem("logged"));

  return (
    <div className="container-fluid bg-light  sticky-top ">
      <nav className="navbar navbar-expand-md navbar-light shadow " style={cus}>
        <p className="navbar-brand"> Afro Info </p>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarColor03"
          aria-controls="navbarColor03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarColor03">
          <ul className="navbar-nav ms-auto text-center">
            <li className="nav-item">
              <Link className="nav-link active" to="/">
                ጨረታ
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="#">
                የቢዝነስ መረጃ
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/your-saved-tenders">
                የተቀመጠ
              </Link>
            </li>
            {/* <li className="nav-item">
                                    <Link className="nav-link" to="/datainput">Editor</Link>
                                </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="#">
                ያውሩን
                <span className="ms-1">
                  <i className="bi bi-chat-dots-fill"></i>
                </span>
              </Link>
            </li>
            <li className="nav-item">
              {logged ? (
                <Link
                  className="nav-link"
                  to="/Login"
                  onClick={() => {
                    localStorage.setItem("logged", 0);
                  }}
                >
                  ይውጡ
                </Link>
              ) : (
                <Link className="nav-link" to="/Login">
                  ይግቡ
                </Link>
              )}
            </li>
            <li className="nav-item">
              {logged ? (
                <i className="bi bi-person-circle nav-link"></i>
              ) : (
                <Link className="nav-link" to="/Register">
                  ይመዝገቡ
                </Link>
              )}
            </li>
          </ul>
        </div>
      </nav>

      {/* <Navbar
        activeTab={activeTab}
        SetActiveTab={SetActiveTab}
        category={category}
        setPosts={setPosts}
      /> */}
      {props.children}
      {/* <Outlet /> */}
    </div>
  );
};

export default Layout;
