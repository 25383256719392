import axios from "axios";

// export const fetchAuth = () => {
//  let status = "pending";
//  let result;
//  let AuthStr = localStorage.getItem('token')
//  let suspender = axios(`http://back.afroinfo.net/wp-json/ai/v1/auth`, { 'headers': { 'Authorization': AuthStr } }).then(
//    (r) => {
//      status = "success";
//      result = r.data;

//      console.warn(r.data)
//    },
//    (e) => {
//      status = "error";
//      result = e;
//    }
//  );

// //  useEffect(()=>{
// //      fetchAuth()
// //  },[])

//  return {
//    read() {
//      if (status === "pending") {
//        throw suspender;
//      } else if (status === "error") {
//        throw result;
//      } else if (status === "success") {
//          console.log('really')
//        return result;
//      }
//    },
//  };
// };

//

export const fetchAuth = () => {
  console.log("fetching usr");

  let usrPromise = fetchUsr();

  return {
    ur: wrapPromise(usrPromise),
  };
};

//

const wrapPromise = (promise) => {
  let status = "pending";
  let result;
  let suspender = promise.then(
    (res) => {
      status = "success";
      result = res;
    },
    (err) => {
      status = "error";
      result = err;
    }
  );

  return {
    read() {
      if (status === "pending") {
        console.log("suspender");
        throw suspender;
      } else if (status === "error") {
        console.log("error in read");
        throw result;
      } else if (status === "success") {
        console.log("success in read");
        console.log("result in read");
        console.log(result);
        console.log("promise in read");
        console.log(promise);
        return result;
      }
    },
  };
};

const fetchUsr = () => {
  console.log("fetching usr");
  let AuthStr = localStorage.getItem("token");
  let r = axios
    .get(`http://back.afroinfo.net/wp-json/ai/v1/auth`, {
      headers: { Authorization: AuthStr },
    })
    .then((res) => res.data)
    .catch((err) => console.warn(err));

  return r;
};
