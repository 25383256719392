
import React, { useState } from 'react';

function DateTime() {

    const [d1, setD1] = useState()
    const [d2, setD2] = useState()
    const [result, setResult] = useState('nothing')


    const showDate = new Date();
    const displayDate = showDate.getDate()+'/'+(showDate.getMonth()+1)+'/'+showDate.getFullYear();
    const displayTime = showDate.getHours()+':'+(showDate.getMinutes()+1)+':'+showDate.getUTCSeconds();
    const dt = showDate.toDateString();



   


    const handleOnChange = (event) => {
        if (event.target.name === 'd1'){
            setD1( event.target.value)
        }
        if (event.target.name === 'd2'){
            setD2( event.target.value)
        }

        

    }

    
    
    function cal() {

        

        // if(d1>d2){setResult('d1>d2')}

        // if(d1<d2){setResult('d1<d2')}

        // if(d1===d2){setResult('d1=d2')}

        const gap = d1 - d2;
        setResult(gap);
        

        // result = ('d1>d2' ? (d1>d2) : ('d1<d2' ? (d1<d2) : 'd1=d2') )
    }
    


  return(

    <div>

        <input type='date' name='d1' onChange={handleOnChange} />
        {d1}
        <br/>
        <input type='date' name='d2' onChange={handleOnChange} />
        {d2}
        <br/>
        {result}
        <br/>
        <button onClick={cal}>Calculate</button>

        {/* <br/>
        {displayDate}
        <br/>
        {dt}
        <br/>
        {displayTime} */}
    </div>  

  ) 
}

export default DateTime;
