import React from 'react'

function ExpiredAcc() {
  return (
    <>

                
                {/* <div className='container'>
                    <div className='row g-3'>
                        <div className='col-12 col-md-6'> */}
                            <div className='card'>
                                <div className='card-body'>
                                    <h5 className='card-title'>
                                        Your Account is expired!
                                    </h5>
                                    <p className='card-text'>
                                            please pay your monthly payment throu the following address to reactivate your account                                    
                                    </p>
                                    {/* <div className='d-flex justify-content-between'>
                                        <a className='btn btn-outline-secondary'>Open</a>
                                        <a className='btn btn-outline-secondary'>Save</a>   
                                    </div> */}
                                    
                                </div>
                                {/* <div className='card-footer d-grid gap-2 d-lg-flex justify-content-lg-between '>   
                                    <a className='btn btn-outline-secondary btn-sm'>Location: Addis Ababa</a>
                                    <a className='btn btn-outline-secondary btn-sm'>Posted: 2 Days ago</a>
                                    <a className='btn btn-outline-secondary btn-sm'>Status: Open</a>
                                </div> */}
                            </div>
                        {/* </div> */}
                        {/* card one end */}
                        {/* <div className='col-12 col-md-6'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5 className='card-title'>
                                        Card title here it can also be an excerpt
                                    </h5>
                                    <p className='card-text'>
                                            aaaaaaaaaaaaaaaaaaaabbbbbbbbbbbbbbbbccccccccccccccccccc                                    
                                    </p>
                                    <div className='d-flex justify-content-between'>
                                        <a className='btn btn-outline-secondary'>Open</a>
                                        <a className='btn btn-outline-secondary'>Save</a>   
                                    </div>
                                    
                                </div>
                                <div className='card-footer d-grid gap-2 d-lg-flex justify-content-lg-between '>   
                                    <a className='btn btn-outline-secondary btn-sm'>Location: Addis Ababa</a>
                                    <a className='btn btn-outline-secondary btn-sm'>Posted: 2 Days ago</a>
                                    <a className='btn btn-outline-secondary btn-sm'>Status: Open</a>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className='col-12 col-md-6'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5 className='card-title'>
                                        Card title here it can also be an excerpt
                                    </h5>
                                    <p className='card-text'>
                                            aaaaaaaaaaaaaaaaaaaabbbbbbbbbbbbbbbbccccccccccccccccccc                                    
                                    </p>
                                    <div className='d-flex justify-content-between'>
                                        <a className='btn btn-outline-secondary'>Open</a>
                                        <a className='btn btn-outline-secondary'>Save</a>   
                                    </div>
                                    
                                </div>
                                <div className='card-footer d-grid gap-2 d-lg-flex justify-content-lg-between '>   
                                    <a className='btn btn-outline-secondary btn-sm'>Location: Addis Ababa</a>
                                    <a className='btn btn-outline-secondary btn-sm'>Posted: 2 Days ago</a>
                                    <a className='btn btn-outline-secondary btn-sm'>Status: Open</a>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className='col-12 col-md-6'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5 className='card-title'>
                                        Card title here it can also be an excerpt
                                    </h5>
                                    <p className='card-text'>
                                            aaaaaaaaaaaaaaaaaaaabbbbbbbbbbbbbbbbccccccccccccccccccc                                    
                                    </p>
                                    <div className='d-flex justify-content-between'>
                                        <a className='btn btn-outline-secondary'>Open</a>
                                        <a className='btn btn-outline-secondary'>Save</a>   
                                    </div>
                                    
                                </div>
                                <div className='card-footer d-grid gap-2 d-lg-flex justify-content-lg-between '>   
                                    <a className='btn btn-outline-secondary btn-sm'>Location: Addis Ababa</a>
                                    <a className='btn btn-outline-secondary btn-sm'>Posted: 2 Days ago</a>
                                    <a className='btn btn-outline-secondary btn-sm'>Status: Open</a>
                                </div>
                            </div>
                        </div> */}
                        
                        
                    {/* </div>
                </div> */}
            </>
  )
}

export default ExpiredAcc