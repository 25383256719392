import React, { useState } from "react";
import axios from "axios";
import MultiSelect from "multiselect-react-dropdown";

import "../../App.css";
import EditorDashboard from "./EditorDashboard";

function DataInput() {
  // const [ loading, setLoading ] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState([1]);
  const [location, setLocation] = useState([]);
  const [expDate, setExpDate] = useState("");
  const [expDateR, setExpDateR] = useState(0);
  const [body, setBody] = useState("");
  const [message, setMessage] = useState("");
  const [postCreated, setPostCreated] = useState();

  const handleOnChange = (event) => {
    if (event.target.name === "title") {
      setTitle(event.target.value);
    }
    if (event.target.name === "body") {
      setBody(event.target.value);
    }

    if (event.target.name === "expDate") {
      setExpDate(event.target.value);
    }
  };

  const onSelectCategory = (selectedList) => {
    let temp = [];
    for (let i = 0; i < selectedList.length; i++) {
      temp[i] = selectedList[i].value;
    }

    setCategory(temp);
  };

  const onRemoveCategory = (selectedList) => {
    let temp = [];
    for (let i = 0; i < selectedList.length; i++) {
      temp[i] = selectedList[i].value;
    }

    setCategory(temp);
  };

  const onSelectLocation = (selectedList) => {
    let temp = [];
    for (let i = 0; i < selectedList.length; i++) {
      temp[i] = selectedList[i].value;
    }

    setLocation(temp);
  };

  const onRemoveLocation = (selectedList) => {
    let temp = [];
    for (let i = 0; i < selectedList.length; i++) {
      temp[i] = selectedList[i].value;
    }

    setLocation(temp);
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    console.log(expDateR);

    const siteUrl = "http://back.afroinfo.net";
    const formData = {
      status: "publish",
      title: title,
      content: body,
      categories: category,
      fields: {
        location: location,
        expiry_date: expDate,
        expiry_date_range: expDateR,
      },
    };

    console.log(formData);

    // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NDM1MTYyMjYsImlzcyI6Imh0dHA6XC9cL2JhY2suYWZyb2luZm8ubmV0IiwiZXhwIjoxNjQzNjAyNjI3LCJqdGkiOiJmMTU1NGQ0ZC0wYzFmLTQ2NTctYWQyZC05Yzg5MmNkZDE4YzAiLCJ1c2VySWQiOjIsInJldm9jYWJsZSI6dHJ1ZSwicmVmcmVzaGFibGUiOmZhbHNlfQ.nRyHHdtQc1nB4jJ63hxUropCngL-U5V-h-ME5u1MqSY'

    // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTAxMTIxMzYsImlzcyI6Imh0dHA6XC9cL2JhY2suYWZyb2luZm8ubmV0IiwiZXhwIjoxNjUwMTk4NTM2LCJqdGkiOiJlNTllMWIzYi0xMjhkLTRhODAtYWI1MS1lODgxYWQ2NDM1MzEiLCJ1c2VySWQiOjEsInJldm9jYWJsZSI6dHJ1ZSwicmVmcmVzaGFibGUiOm51bGx9.XZr0p5NL_0Cr8n6wkL26QWHvPGR0FjpufhTHROw-7GI'

    const token = localStorage.getItem("token");

    const headers = {
      // 'Content-Type': 'application/json',
      // 'Accept': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    //   useEffect(() => {
    //   setLoading(true)

    axios
      .post(`${siteUrl}/wp-json/wp/v2/posts`, formData, { headers })
      .then((res) => {
        // if(undefined === res.data.jwt.token){
        //     setError(res.data.message );
        //     setLoading(false);
        //     return;
        // }

        // localStorage.setItem('token', res.data.jwt.token);
        // localStorage.setItem('userName', res.data.user.data.user_nicename);

        // setLoading(false);
        // setToken(res.data.jwt.token);
        // setUsername(res.data.user.data.user_nicename);
        // setEmail(res.data.user.data.user_email);
        // setLoggedIn(true);
        // if(location.state?.from){
        //     navigate(location.state.from , { replace: true });
        // }
        // else{
        //     navigate("/" , { replace: true })
        // }

        console.log(res);

        // setLoading(false),

        setPostCreated(!!res.data.id);
        setMessage(res.data.id ? "New post created" : "");
      })

      .catch((err) => {
        // setError(err.response.data);
        // setLoading(false);

        setMessage(err.response.data.message);

        console.log(err);
        console.log("----------");
        console.log(err.response.data);
      });

    // }, [] )
  }; // end of onFormSubmit function

  const logger = localStorage.getItem("userName");

  return (
    <EditorDashboard>
      <div className="container mt-3">
        <form onSubmit={onFormSubmit}>
          {message ? (
            <div
              className={`alert ${
                postCreated ? "alert-success" : "alert-danger"
              }`}
            >
              {" "}
              {message}{" "}
            </div>
          ) : (
            ""
          )}
          <div className="text-center mb-3">
            <h4>ሰላም የተከበሩ {logger}, እያንዳንዱን መረጃ በጥንቃቄ ያስገቡ!</h4>
          </div>

          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              name="title"
              value={title}
              onChange={handleOnChange}
              placeholder="Title"
            />
          </div>

          <div className="row mb-3">
            <div className="col-lg-6 mb-3">
              <MultiSelect
                className="multi"
                disablePreSelectedValues
                displayValue="name"
                options={[
                  { name: "ሁሉም ጨረታዎች", value: "1" },
                  { name: "የሕንጻ መሳሪያ እና የኤሌክትሪክ ዕቃዎች", value: "2" },
                  { name: "ፊንሺንግ እና ኮንስትራክሽ", value: "3" },
                  { name: "የተሽከርካሪዎች ሃራጅ", value: "4" },
                  { name: "የስፖርት እቃዎችና ትጥቅ", value: "5" },
                  { name: "የኢንዱስትሪ ዕቃዎችና ኬሚካሎች", value: "6" },
                ]}
                onSelect={onSelectCategory}
                onRemove={onRemoveCategory}
                isObject={true}
                placeholder="Category"
                selectedValues={[{ name: "ሁሉም ጨረታዎች", value: "1" }]}
              />
            </div>
            <div className="col-lg-6">
              <MultiSelect
                className="multi"
                displayValue="name"
                options={[
                  { name: "Addis Ababa", value: "Addis Ababa" },
                  { name: "Oromia", value: "Oromia" },
                  { name: "Gambella", value: "Gambella" },
                  { name: "Amhara", value: "Amhara" },
                  { name: "SNNPR", value: "SNNPR" },
                  { name: "Afar", value: "Afar" },
                ]}
                onSelect={onSelectLocation}
                onRemove={onRemoveLocation}
                isObject={true}
                placeholder="Location"
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-6 mb-3">
              <input
                className="form-control"
                name="expDate"
                value={expDate}
                onChange={handleOnChange}
                type="date"
              />
            </div>
            <div className="col-lg-6">
              <MultiSelect
                className="multi"
                displayValue="name"
                options={[
                  { name: "After one day", value: "1" },
                  { name: "After two days", value: "2" },
                  { name: "After three days", value: "3" },
                  { name: "After four days", value: "4" },
                  { name: "After five days", value: "5" },
                  { name: "After six days", value: "6" },
                ]}
                onSelect={(event) => {
                  setExpDateR(event[0].value);
                }}
                onRemove={(event) => {
                  setExpDateR(0);
                }}
                isObject={true}
                singleSelect
                placeholder="After days"
              />
            </div>
          </div>

          <div className="mb-3">
            <textarea
              type="text"
              className="form-control"
              rows="5"
              name="body"
              value={body}
              onChange={handleOnChange}
              placeholder="Body"
            ></textarea>
          </div>

          <div className="d-flex justify-content-between">
            <button className="btn btn-primary mb-3" id="save" type="submit">
              Save
            </button>
            <button className="btn btn-primary mb-3" id="publish" type="submit">
              Publish
            </button>
          </div>
        </form>
      </div>
    </EditorDashboard>
  );
}

export default DataInput;
