import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "./Layout";

function Register() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [code, setCode] = useState("");

  const onFormSubmit = (event) => {
    event.preventDefault();

    if (!email) {
      let temp = userName + "@nomail.com";
      setEmail(temp);
    }

    const siteUrl = "http://back.afroinfo.net";

    const formData = {
      username: userName,
      password: password,
      first_name: firstName,
      last_name: lastName,
      email: email ? email : userName + "@nomail.com",
      meta_input: { phone: phone },
    };

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    //   useEffect(() => {
    setLoading(true);

    axios
      .post(`${siteUrl}/wp-json/wp/v2/users/register`, formData, { headers })
      .then((res) => {
        if (200 !== res.data.code) {
          setError(res.data.message);
          console.log("not 200");
          console.log(res.data.message);
          setLoading(false);
          return;
        }

        setError(res.data.message);
        console.log(res.data.message);
        setStatus(res.data.code);

        setLoading(false);

        // if(location.state?.from){

        //     navigate(location.state.from ,  { state:{ loc: location.state.from.state.loc } } , { replace: true } );
        // }
        // else{
        //     navigate("/" , { replace: true })
        // }
      })

      .catch((err) => {
        if (err.response.status) {
          setStatus(err.response.status);
          console.log(err.response.status);
          setError(err.response.data.message);
          console.log(err.response.data.message);
        }

        setError(err.response.data);
        console.log("err.response.data");
        console.log(err.response.data);
        setCode(err.response.data.code);
        setLoading(false);
      });

    // }, [] )
  }; // end of onFormSubmit function

  const handleOnChange = (event) => {
    if (event.target.name === "firstName") {
      setFirstName(event.target.value);
    }
    if (event.target.name === "lastName") {
      setLastName(event.target.value);
    }
    if (event.target.name === "userName") {
      setUserName(event.target.value);
    }
    if (event.target.name === "phone") {
      setPhone(event.target.value);
    }
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
    if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  };

  return (
    <>
      <Layout />

      <div className="conta">
        {status === 400 ? (
          <div className="alert alert-danger">
            <h4>{error.message}</h4>{" "}
          </div>
        ) : (
          ""
        )}
        {status === 200 ? (
          <div className="alert alert-success">
            <h4>
              {error} <Link to="/Login">Please back to Login page</Link>
            </h4>{" "}
          </div>
        ) : (
          ""
        )}

        <form onSubmit={onFormSubmit} className="form">
          <h1>ይመዝገቡ</h1>
          <label htmlFor="firstName" className="form-label d-grid">
            ስም
            <input
              type="text"
              className="form-control"
              name="firstName"
              value={firstName}
              onChange={handleOnChange}
              required
            />
          </label>

          <label htmlFor="lastName" className="form-label d-grid">
            የአባት ስም
            <input
              type="text"
              className="form-control"
              name="lastName"
              value={lastName}
              onChange={handleOnChange}
              required
            />
          </label>

          <label htmlFor="userName" className="form-label d-grid">
            User Name
            <input
              type="text"
              className="form-control"
              name="userName"
              value={userName}
              onChange={handleOnChange}
              required
            />
          </label>

          <label htmlFor="phone" className="form-label d-grid">
            ስልክ ቁጥር
            <input
              type="tel"
              className="form-control"
              name="phone"
              value={phone}
              onChange={handleOnChange}
              required
            />
          </label>
          <div className="d-grid">
            <label htmlFor="email" className="form-label">
              ኢሜል{" "}
              <span className="text-muted">
                <small>(optional)</small>
              </span>
              <input
                type="email"
                className="form-control"
                name="email"
                value={email}
                onChange={handleOnChange}
              />
            </label>
          </div>
          <label htmlFor="password" className="form-label d-grid">
            የይለፍ ቃል
            <input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={handleOnChange}
              required
            />
          </label>

          <div className=" d-grid">
            <button className="btn btn-primary" type="submit">
              ይመዝገቡ
            </button>
          </div>
          <p className="forgot-password text-right">
            Account አሎት?{" "}
            <Link className="link" to="/Login">
              ይግቡ
            </Link>
          </p>
        </form>
      </div>
    </>
  );
}

export default Register;
