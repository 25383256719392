import React, { useState } from "react";
import { Link } from "react-router-dom";
import { EditorSidebarData } from "./EditorSidebarData";
import "./UserDashboard.css";

function EditorDashboard(props) {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const cus = {
    padding: "15px",
    margin: "0",
  };

  let logged = parseInt(localStorage.getItem("logged"));
  let user = parseInt(localStorage.getItem("AuthUser"));
  let userName = localStorage.getItem("userName");

  return (
    <>
      <div className="container-fluid bg-light  sticky-top ">
        <nav
          className="navbar navbar-expand-md navbar-light shadow "
          style={cus}
        >
          <p className="navbar-brand"> Afro Info </p>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarColor03"
            aria-controls="navbarColor03"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarColor03">
            <ul className="navbar-nav ms-auto text-center">
              <li className="nav-item">
                <Link className="nav-link active" to="/">
                  ጨረታ
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#">
                  የቢዝነስ መረጃ
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/your-saved-tenders">
                  የተቀመጠ
                </Link>
              </li>
              {/* <li className="nav-item">
                                    <Link className="nav-link" to="/datainput">Editor</Link>
                                </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="#">
                  ያውሩን
                  <span className="ms-1">
                    <i className="bi bi-chat-dots-fill"></i>
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                {logged ? (
                  <Link
                    className="nav-link"
                    to="/Login"
                    onClick={() => {
                      localStorage.setItem("logged", 0);
                    }}
                  >
                    ይውጡ
                  </Link>
                ) : (
                  <Link className="nav-link" to="/Login">
                    ይግቡ
                  </Link>
                )}
              </li>
              <li className="nav-item">
                {logged ? (
                  <i className="bi bi-list nav-link" onClick={showSidebar}></i>
                ) : (
                  <Link className="nav-link" to="/Register">
                    ይመዝገቡ
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </nav>

        {/* <div className="navbar2">
        <a className="menu-bars">
          <i className="bi bi-list" onClick={showSidebar}></i>
        </a>
      </div> */}
        <nav
          className={sidebar ? "bg-light nav-menu active" : "bg-light nav-menu"}
        >
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <i className="bi bi-person-circle"> {` ${userName}`}</i>
              <a className="menu-bars">
                <small>
                  <i className="bi bi-arrow-right-circle-fill"></i>
                </small>
              </a>
            </li>
            {EditorSidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
      {/* <Navbar
        activeTab={activeTab}
        SetActiveTab={SetActiveTab}
        category={category}
        setPosts={setPosts}
      /> */}
      {props.children}
      {/* <Outlet /> */}
    </>
  );
}

export default EditorDashboard;
