import React, { useState, useEffect } from "react";
import UserDashboard from "./User/UserDashboard";
import AdSection from "./AdSection";
import { Link } from "react-router-dom";
import "./Profile.css";

function Profile() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [code, setCode] = useState("");
  const [pic, setPic] = useState("");

  useEffect(() => {
    setFirstName(localStorage.getItem("userName"));
    setEmail(localStorage.getItem("email"));
  }, []);

  const handleOnChange = (event) => {
    if (event.target.name === "firstName") {
      setFirstName(event.target.value);
    }
    if (event.target.name === "lastName") {
      setLastName(event.target.value);
    }

    if (event.target.name === "phone") {
      setPhone(event.target.value);
    }
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
    if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  };

  return (
    <UserDashboard>
      <div className="container my-4">
        <div className="row">
          {/* start of ad section */}
          <div className="col-lg-4 d-none d-lg-inline text-center">
            <AdSection />
          </div>
          {/* end of ad section */}

          <div className="col-lg-8">
            <div className="container mt-4 mb-4 p-3 d-flex justify-content-center">
              <div className="card cardP p-4">
                <div className=" image d-flex flex-column justify-content-center align-items-center">
                  <button className="btn btn-secondary btnP">
                    <img
                      src="https://i.imgur.com/wvxPV9S.png"
                      height="100"
                      width="100"
                    />
                  </button>
                  <span className="name mt-3">
                    {firstName} {lastName}
                  </span>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <span>
                      <i className="bi bi-phone"></i>
                    </span>
                    <span className="idd">{phone}</span>
                  </div>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <span>
                      <i className="bi bi-envelope"></i>
                    </span>
                    <span className="idd1">{email}</span>
                  </div>
                  <div className="d-flex flex-row justify-content-center align-items-center mt-3">
                    <span className="number">
                      277 <span className="follow">Days left</span>
                    </span>
                  </div>
                  <div className=" d-flex mt-2">
                    <button className="btn1 btn-dark">Edit Profile</button>
                  </div>
                  <div className="text mt-3">
                    <ul class="list-group">
                      <li className="list-group-item text-center">
                        Your Category priority
                      </li>
                      <li className="list-group-item list-group-item-light"></li>
                      <li className="list-group-item list-group-item-dark">
                        የሕንጻ መሳሪያ እና የኤሌክትሪክ ዕቃዎች
                      </li>
                      <li className="list-group-item list-group-item-secondary">
                        ፊንሺንግ እና ኮንስትራክሽ
                      </li>
                      <li className="list-group-item list-group-item-dark">
                        የተሽከርካሪዎች ሃራጅ
                      </li>
                      <li className="list-group-item list-group-item-secondary">
                        የስፖርት እቃዎችና ትጥቅ
                      </li>
                      <li className="list-group-item list-group-item-dark">
                        የኢንዱስትሪ ዕቃዎችና ኬሚካሎች
                      </li>
                    </ul>
                  </div>
                  {/* <div className="gap-3 mt-3 icons d-flex flex-row justify-content-center align-items-center">
                    <span>
                      <i className="bi bi-twitter"></i>
                    </span>
                    <span>
                      <i className="bi bi-facebook"></i>
                    </span>
                    <span>
                      <i className="bi bi-instagram"></i>
                    </span>
                    <span>
                      <i className="bi bi-linkedin"></i>
                    </span>
                  </div>{" "} */}
                  <div className=" px-2 rounded mt-4 date ">
                    <span className="join">Joined May,2021</span>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="profile">
              <form className="form">
                <h1>Profile</h1>
                <label htmlFor="firstName" className="form-label d-grid">
                  ስም
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    value={firstName}
                    onChange={handleOnChange}
                    required
                  />
                </label>

                <label htmlFor="lastName" className="form-label d-grid">
                  የአባት ስም
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    value={lastName}
                    onChange={handleOnChange}
                    required
                  />
                </label>
                <label htmlFor="phone" className="form-label d-grid">
                  ስልክ ቁጥር
                  <input
                    type="tel"
                    className="form-control"
                    name="phone"
                    value={phone}
                    onChange={handleOnChange}
                    required
                  />
                </label>
                <div className="d-grid">
                  <label htmlFor="email" className="form-label">
                    ኢሜል{" "}
                    <span className="text-muted">
                      <small>(optional)</small>
                    </span>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={handleOnChange}
                    />
                  </label>
                </div>
                <label htmlFor="password" className="form-label d-grid">
                  የይለፍ ቃል
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={handleOnChange}
                    required
                  />
                </label>

                <div className=" d-grid">
                  <button className="btn btn-primary" type="submit">
                    ይመዝገቡ
                  </button>
                </div>
              </form>
            </div> */}
          </div>
        </div>
      </div>
    </UserDashboard>
  );
}

export default Profile;
