import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Test from "./Test";
import DOMPurify from "dompurify";
import Layout from "./Layout";
import { fetchAuth } from "./fetchAuth";

const resource = fetchAuth();

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [token, setToken] = useState("");
  const [exp2, setExp2] = useState("");
  const [exp3, setExp3] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);

  const onFormSubmit = (event) => {
    event.preventDefault();

    const siteUrl = "http://back.afroinfo.net";
    const loginData = {
      username: username,
      password: password,
      issueJWT: true,
    };

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    //   useEffect(() => {
    setLoading(true);

    axios
      .post(`${siteUrl}/wp-json/aam/v2/authenticate`, loginData, { headers })
      .then((res) => {
        if (undefined === res.data.jwt.token) {
          setError(res.data.message);
          console.log("when token is undefined");
          console.log(res.data.message);
          setLoading(false);
          return;
        }

        localStorage.setItem("token", res.data.jwt.token);
        localStorage.setItem("userName", res.data.user.data.user_nicename);
        localStorage.setItem("email", res.data.user.data.user_email);
        localStorage.setItem("ID", res.data.user.data.ID);
        localStorage.setItem("logged", 1);

        setLoading(false);
        setToken(res.data.jwt.token);
        setUsername(res.data.user.data.user_nicename);
        setEmail(res.data.user.data.user_email);
        setLoggedIn(true);

        if (location.state?.from) {
          navigate(
            location.state.from,
            { state: { loc: location.state.from.state.loc } },
            { replace: true }
          );
        } else {
          let user = resource.ur.read();

          console.warn(user);
          localStorage.setItem("AuthUser", user);

          if (user === 3) {
            navigate("/", { replace: true });
          } else if (user === 2) {
            navigate("/DataInput", { replace: true });
          } else if (user === 1) {
            navigate("/Dashboard", { replace: true });
          } else if (user === -1) {
            navigate("/ExpiredAcc", { replace: true });
          } else {
            navigate("/", { replace: true });
          }
        }
      })

      .catch((err) => {
        if (err.response.status) {
          localStorage.setItem("err2", err.response.status);
          console.log("err2");
          console.log(localStorage.getItem("err2"));

          setError(err.response.data.message);

          setExp2(parseInt(localStorage.getItem("err2")));
          setExp3(err.response.data.code);
        }

        setError(err.response.data);
        console.log("err.response.status");
        console.log(err.response.status);
        setLoading(false);
      });

    // }, [] )
  }; // end of onFormSubmit function

  // onFormSubmit = (event) => {
  //     event.preventDefault();

  //     const siteUrl = 'http://back.afroinfo.net';
  //     const loginData = {
  //         username: this.state.username,
  //         password: this.state.password,

  //     }

  //     this.setState({loading: true}, () => {
  //         axios.post(`${siteUrl}/wp-json/jwt-auth/v1/token`, loginData)
  //         .then(res => {
  //             if(undefined === res.data.token){
  //                 this.setState({error:res.data.message, loading: false});
  //                 return;
  //             }
  //             localStorage.setItem('token', res.data.token);
  //             localStorage.setItem('userName', res.data.user_nicename);

  //             this.setState({
  //                 loading: false,
  //                 token: res.data.token,
  //                 username: res.data.user_nicename,
  //                 email: res.data.user_email,
  //                 loggedIn: true

  //             })
  //         })

  //         .catch(err => {
  //             this.setState({error: err.response.data, loading: false});
  //         })

  //     })
  // } // end of onFormSubmit function

  const handleOnChange = (event) => {
    if (event.target.name === "username") {
      setUsername(event.target.value);
    }
    if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  };

  // const user = username ? username : localStorage.getItem('userName');

  // if(loggedIn || localStorage.getItem('token')){

  //     return <Navigate to={ `/dashboard/${user}` } noThrow />

  // } else{

  return (
    <>
      <Layout />
      {/* {error && <div className='alert alert-danger'>{error.code} </div>} */}
      {exp2 === 403 && exp3 === "incorrect_password" ? (
        <div className="alert alert-danger">
          <h4>bro check ur password</h4>
          <Link className="nav-link" to="/">
            Reset Password
          </Link>{" "}
        </div>
      ) : (
        ""
      )}
      {exp2 === 403 && exp3 === "invalid_username" ? (
        <div className="alert alert-danger">
          <h4>bro check ur name</h4> <p>or</p>{" "}
          <Link className="nav-link" to="/">
            Register
          </Link>{" "}
        </div>
      ) : (
        ""
      )}
      {/* {error && <div className='alert alert-danger' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(error.reason) }} />} */}

      <div className=" mt-4">
        <form onSubmit={onFormSubmit} className="form">
          <h1>ይግቡ</h1>
          <label className="form-label d-grid">
            የደንበኝነት ስም
            <input
              type="text"
              className="form-control"
              name="username"
              value={username}
              onChange={handleOnChange}
            />
          </label>

          <label className="form-label d-grid">
            የይለፍ ቃል
            <input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={handleOnChange}
            />
          </label>
          <div className=" d-grid">
            <button className="btn btn-primary mb-3" type="submit">
              ይግቡ
            </button>
          </div>
          <p className="forgot-password text-right">
            Account የሎትም?{" "}
            <Link className="link" to="/Register">
              ይመዝገቡ
            </Link>
          </p>
        </form>
      </div>
    </>
  );

  // }
}

export default Login;
