import React from "react";

export const EditorSidebarData = [
  {
    title: "Profile",
    path: "/profile",
    icon: <i className="bi bi-person-lines-fill"></i>,
    cName: "nav-text",
  },
  {
    title: "Add New",
    path: "/datainput",
    icon: <i className="bi bi-file-earmark-plus-fill"></i>,
    cName: "nav-text",
  },
  {
    title: "Edit Saved",
    path: "/draft",
    icon: <i className="bi-bookmark-check-fill"></i>,
    cName: "nav-text",
  },
  {
    title: "Messages",
    path: "/messages",
    icon: <i className="bi bi-envelope-paper-fill"></i>,
    cName: "nav-text",
  },
  {
    title: "Support",
    path: "/support",
    icon: <i className="bi bi-question-circle-fill"></i>,
    cName: "nav-text",
  },
];
