import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams, useLocation } from "react-router-dom";
import "./custom.css";

function Navbar({ activeTab, SetActiveTab, category, setPosts }) {
  // const [ activeTab, SetActiveTab ] = useState(1)

  //    const {category} = useParams();

  // console.log(activeTab)
  // console.log(category)

  // useEffect(()=>{
  //     if(category !== undefined){
  //         SetActiveTab(category)
  //     }

  // }, [])

  // console.log(activeTab)

  // console.log('---before---')
  // console.log(activeTab)
  // console.log(category)

  // const location = useLocation();
  // console.log(location);

  // console.log('category in navbar');
  // console.log(category);
  // console.log('ActiveTab');
  // console.log(activeTab);

  useEffect(() => {
    if (category !== undefined && activeTab !== category) {
      SetActiveTab(category);
    }
  }, [category]);

  // console.log('---after---')
  // console.log(activeTab)
  // console.log(category)

  const activateTab = (index) => {
    setPosts("");
    console.log("posts reseted in nav bar");
    SetActiveTab(index);
  };

  const custom = {
    marginTop: "15px",
    marginBottom: "15px",
    // paddingTop: '15px',
    // borderStyle: 'solid',
    // borderColor: 'lightgrey',
    // borderWidth: '1px 0 0 0'
  };

  const cat1 = 1;
  const cat2 = 9;
  const cat3 = 11;
  const cat4 = 2;
  const cat5 = 5;
  const cat6 = 8;
  const cat7 = 4;
  const cat8 = 6;
  const cat9 = 10;
  const cat10 = 7;
  const cat11 = 3;

  return (
    <>
      <div className="container" style={custom}>
        <ul className="nav nav-tabs">
          {/* <li className='nav-item'>
                                <h3>{activeTab}</h3>
                            </li> */}

          <li className="nav-item">
            <NavLink
              onClick={() => activateTab(cat1)}
              className={activeTab === cat1 ? "nav-link active" : "nav-link"}
              to={`/category/${cat1}`}
              aria-current="page"
            >
              ሁሉም ጨረታዎች
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={() => activateTab(cat4)}
              className={activeTab === cat4 ? "nav-link active" : "nav-link"}
              to={`/category/${cat4}`}
            >
              የሕንጻ መሳሪያ እና የኤሌክትሪክ ዕቃዎች
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={() => activateTab(cat11)}
              className={activeTab === cat11 ? "nav-link active" : "nav-link"}
              to={`/category/${cat11}`}
            >
              ፊንሺንግ እና ኮንስትራክሽ
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={() => activateTab(cat7)}
              className={activeTab === cat7 ? "nav-link active" : "nav-link"}
              to={`/category/${cat7}`}
            >
              የተሽከርካሪዎች ሃራጅ
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={() => activateTab(cat5)}
              className={activeTab === cat5 ? "nav-link active" : "nav-link"}
              to={`/category/${cat5}`}
            >
              የስፖርት እቃዎችና ትጥቅ
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={() => activateTab(cat8)}
              className={activeTab === cat8 ? "nav-link active" : "nav-link"}
              to={`/category/${cat8}`}
            >
              የኢንዱስትሪ ዕቃዎችና ኬሚካሎች
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={() => activateTab(cat10)}
              className={activeTab === cat10 ? "nav-link active" : "nav-link"}
              to={`/category/${cat10}`}
            >
              የኪራይ ጨረታዎች
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={() => activateTab(cat6)}
              className={activeTab === cat6 ? "nav-link active" : "nav-link"}
              to={`/category/${cat6}`}
            >
              የቢሮ እቃዎች እና ኤሌክትሮኒክስ
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={() => activateTab(cat2)}
              className={activeTab === cat2 ? "nav-link active" : "nav-link"}
              to={`/category/${cat2}`}
            >
              ህትመት እና ማስታወቂያ
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={() => activateTab(cat9)}
              className={activeTab === cat9 ? "nav-link active" : "nav-link"}
              to={`/category/${cat9}`}
            >
              የእንስሳ ዉጤቶች
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={() => activateTab(cat3)}
              className={activeTab === cat3 ? "nav-link active" : "nav-link"}
              to={`/category/${cat3}`}
            >
              ትምህርት፣ ስልጠና እና ማማከር
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navbar;
