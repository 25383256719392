import React, { useState, useEffect } from 'react'
import Navbar from './Navbar'
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import Loader from '../loader.gif';
import '../App.css'



function AllPost() {

    const [posts, setPosts] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')



    const { category } = useParams();

    

    
    useEffect(() => 
    {

    
        setLoading(true)
        axios.get(`http://back.afroinfo.net/wp-json/wp/v2/posts?categories=${category}`)
         .then(res => {
             
                setLoading(false)
                setPosts(res.data)
                
             
         })
         .catch(function(error){setError(error.response.data.message);setLoading(false);} )

    }, [category] )










        return (
            <div>
                <Navbar />
                {error && <div className='alert alert-danger'>{error}</div>}
                {posts.length ? (

                        <div className='container'>
                            <div className='row g-3'>

                                <div className='col-lg-4 col-xl-3'>
                                    {/* <Sidebar /> */}
                                </div>

                                <div className='col-lg-8 col-xl-9'>
                                    <div className='row cont d-flex flex-justify-content-center flex-wrap'>
                                        {posts.map(post => (
                                            <div className='card col-md-5 col-lg-12 col-xl-5' key={post.id}  >
                                                <div className='card-body'>
                                                    <Link to={`/post/${post.id}`}>
                                                        <h5 className='card-title'>
                                                            {post.title.rendered}
                                                        </h5>
                                                    </Link>
                                                    <div className='loc d-flex justify-content-between'>
                                                        <div className=' text-muted'>
                                                            Location: Addis Ababa 
                                                        </div>
                                                        <button className='btn btn-outline-secondary btn-sm rounded-pill' >
                                                        <Link to={`/post/${post.id}`} >Open</Link>
                                                        </button>
                                                        <a className='btn btn-outline-secondary btn-sm rounded-pill'>Save</a>
                                                    </div>
                                                    
                                                </div>
                                                
                                                    <hr/>    
                                                
                                                
                                                <div className='card-f d-flex justify-content-between '>   
                                                    
                                                    <div className='card-text text-secondary'>
                                                        <Moment fromNow>{post.date}</Moment>
                                                    </div>
                                                    <div className='text-muted'>Status: Open</div>
                                                </div>
                                            </div>
                                    
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                ) : ''}
                
                { loading && <img className='loader' src={Loader} alt='Loader'/> } 
                
                
                
            </div>
        ) 


        
    
    
 



}

export default AllPost;
