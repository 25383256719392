import React, { Suspense, useState, useEffect, createContext } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Test from "./components/Test";
import SinglePost from "./components/SinglePost";
import AllPost from "./components/AllPost";
import Protected from "./components/Protected";
import Navbar from "./components/Navbar";
import DataInput from "./components/Editor/DataInput";
import ExpiredAcc from "./components/ExpiredAcc";
import Register from "./components/Register";
import DateTime from "./components/DateTime";
import EditorDashboard from "./components/Editor/EditorDashboard";
import axios from "axios";
import Layout from "./components/Layout";
import Saved from "./components/Saved";
import Profile from "./components/Profile";

// export const ActiveContext = createContext();

function App() {
  const [firstPage, setFirstPage] = useState(1);

  const [activeTab, SetActiveTab] = useState(1);
  //   const [ navCategory, setNavCategory ] = useState(1)

  //   console.log('activeTab')
  //   console.log(activeTab)
  //   console.log('navCategory')
  //   console.log(navCategory)

  //   useEffect(()=>{
  //     if(navCategory !== undefined){
  //         SetActiveTab(navCategory)
  //     }

  // }, [])

  // const activateTab = (index) => {
  //     SetActiveTab(index)
  // }

  return (
    <div className="App">
      <Suspense fallback={<p>suspense in action ...</p>}>
        <Router>
          {/* <Navbar activeTab={activeTab} SetActiveTab={SetActiveTab} /> */}
          <Routes>
            <Route
              path="/"
              element={
                <Test activeTab={activeTab} SetActiveTab={SetActiveTab} />
              }
            />
            <Route
              path="/category/:category"
              element={
                <Test
                  firstPage={firstPage}
                  setFirstPage={setFirstPage}
                  activeTab={activeTab}
                  SetActiveTab={SetActiveTab}
                />
              }
            />
            {/* <Route path='/' element={<Test setNavCategory={setNavCategory} />} />
          <Route path='/category/:category' element={<Test setNavCategory={setNavCategory} />} /> */}
            <Route path="/allpost/:category" element={<AllPost />} />
            {/* <Route path='/' element={<Home/>} /> */}

            <Route path="/login" element={<Login />} />

            <Route element={<Protected />}>
              <Route
                path="/post/:slug"
                element={<SinglePost setFirstPage={setFirstPage} />}
              />
            </Route>

            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/datainput" element={<DataInput />} />
            <Route path="/ExpiredAcc" element={<ExpiredAcc />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/d" element={<DateTime />} />
            <Route path="/your-saved-tenders" element={<Saved />} />
            {/* <Route path="/admin/*" element={<EditorDashboard/>}/> */}
            {/* <Route path="*" element={<Navigate to="/" />}/> */}
            <Route path="/profile" element={<Profile />} />
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
