import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import axios from "axios";
import {
  useParams,
  Navigate,
  useNavigate,
  Link,
  useLocation,
} from "react-router-dom";
import DOMPurify from "dompurify";
import Moment from "react-moment";
import Loader from "../loader.gif";
import "../App.css";
import UserDashboard from "./User/UserDashboard";
import AdSection from "./AdSection";
function SinglePost({ setFirstPage }) {
  const [post, setPost] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // let loc = location.state.loc.pathname

  //console.log(location);
  //  console.log(loc);

  setFirstPage(location.state.prev);
  //console.log(location.state.prev);

  let AuthStr = localStorage.getItem("token");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`http://back.afroinfo.net/wp-json/ai/v1/posts/${slug}`, {
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        setLoading(false);
        setPost(res.data);
      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
        if (error.response.status) {
          localStorage.setItem("err", error.response.status);
          console.log("err");
          console.log(localStorage.getItem("err"));

          setError(error.response.data.message);

          //    let Exp = parseInt(localStorage.getItem('err'));

          //     if(401 === Exp){

          //         setLoading(false);

          //         navigate("/ExpiredAcc" )

          //     }
        }

        setLoading(false);
      });
  }, [slug]);

  // if(localStorage.getItem('token')){

  return (
    <UserDashboard>
      <div className="container my-4">
        {/* <Navbar activeTab={activeTab} SetActiveTab={SetActiveTab} category={category} /> */}
        {error && <div className="alert alert-danger">{error}</div>}

        <div className="row">
          {/* start of ad section */}
          <div className="col-lg-4 d-none d-lg-inline text-center">
            <AdSection />
          </div>
          {/* end of ad section */}

          <div className="col-lg-8">
            {Object.keys(post).length ? (
              <div key={post.id} className="card  mb-3 ">
                {/* title */}
                <div className="card-header">{post.title}</div>
                {/* <div className='card-header'>
    
                                        Author Name {post._embedded.author.name}
                                        
                                    </div> */}
                {/* body */}
                <div className="card-body">
                  <div className="card-text post-content">
                    {
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(post.content),
                        }}
                      />
                    }
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-between">
                  Date: {post.date}
                  <br />
                  <Moment fromNow>{post.date}</Moment>
                  <button
                    className="btn btn-outline-secondary btn-sm rounded-pill"
                    onClick={() =>
                      navigate(location.state.loc, { replace: true })
                    }
                  >
                    Back
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        {loading && <img className="loader" src={Loader} alt="Loader" />}
      </div>
    </UserDashboard>
  );

  //  } else{

  //     return <Navigate to={ '/login' } noThrow />

  //  }
}

export default SinglePost;
