import React, { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
  Navigate,
  useParams,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import Moment from "react-moment";
import DOMPurify from "dompurify";
import Card from "./Card";
import Navbar from "./Navbar";
import Register from "./Register";
import Sidebar from "./Sidebar";
import Loader from "../loader.gif";
import "../App.css";
import DateTime from "./DateTime";

import InfiniteScroll from "react-infinite-scroll-component";
import Load from "./Load";
import Layout from "./Layout";

function Test({ activeTab, SetActiveTab, firstPage, setFirstPage }) {
  const [pageCount, setPageCount] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(2);

  const [posts, setPosts] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [saved, setSaved] = useState([]);

  let prev = 1;

  const location = useLocation();
  const navigate = useNavigate();

  let { category } = useParams();

  if (category === undefined) {
    category = 1;
  }

  if (firstPage === undefined) {
    // setFirstPage(1)
  }

  // if (activeTab !== category){
  //         SetActiveTab(category);
  //     }

  // SetActiveTab(category)

  // setNavCategory(category);

  useEffect(() => {
    //console.log('firstPage in Test useEffect')

    //console.log(firstPage)
    setLoading(true);
    setHasMore(true);
    axios
      .get(
        `http://back.afroinfo.net/wp-json/ai/v1/posts?categories=${category}&page_no=${firstPage}`
      )
      .then((res) => {
        setLoading(false);
        setPageCount(res.data[3]);
        setPosts(res.data[4]);
        setFirstPage(1);

        if (res.data[4].length === 0 || res.data[4].length < 5) {
          setHasMore(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
        //console.log('in catch')
        //console.log(error)
        //console.log(error.response.data.message)
        // setError(error.response.data[4].message)
      });
  }, [category]);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(
        `http://back.afroinfo.net/wp-json/ai/v1/posts?categories=${category}&page_no=${page}`
      )
      .then((res) => {
        setLoading(false);
        setPageCount(res.data[3]);
        setPosts([...posts, ...res.data[4]]);
        prev = page;
        console.log("prev");
        console.log(prev);
        setPage(page + 1);

        if (res.data[4].length === 0 || res.data[4].length < 5) {
          setHasMore(false);
          setPage(2);
        }
      })
      .catch(function (error) {
        setError(error.response.data.message);
        setLoading(false);
      });
  };

  // console.log(posts[4])

  // const back =()=>{

  //     <Navigate to={ `/post/${post.id}/${category}` } noThrow replace state={{from: location}} />

  // }

  // const handlePageClick = (event) => {

  //     // console.log(event.selected + 1)
  //     currentPage = (event.selected + 1)
  //     fecher(currentPage);

  //   };

  function save(id) {
    let exist = saved.filter((i) => i === id);

    if (!exist.length) {
      setSaved([...saved, id]);
    }
  }

  return (
    <>
      <Layout>
        <Navbar
          activeTab={activeTab}
          SetActiveTab={SetActiveTab}
          category={category}
          setPosts={setPosts}
        />
      </Layout>
      {error && <div className="alert alert-danger">{error}</div>}

      {/* <DateTime/> */}
      <div className="row g-3">
        <div className=" col-lg-4 d-none d-lg-inline">
          <Sidebar />
        </div>
        {posts.length ? (
          <div className="col-lg-8">
            <InfiniteScroll
              dataLength={posts.length} //This is important field to render the next data
              next={fetchData}
              hasMore={hasMore}
              loader={<h4> ለተጨማሪ ጨረታ... </h4>}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>ሁሉንም ጨረታ አይተዋል</b>
                </p>
              }
            >
              <div className="row cont d-flex flex-justify-content-center flex-wrap">
                {posts.map((post) => (
                  <div
                    className="card card1 col-md-5 col-lg-12 col-xl-5"
                    key={post.id}
                  >
                    <div className="card-body">
                      <Link to={`/post/${post.id}/${category}`}>
                        <h5 className="card-title">{post.title}</h5>
                      </Link>
                      <div className="loc d-flex justify-content-between">
                        <div className=" text-muted">Location: Addis Ababa</div>
                        <button
                          className="btn btn-outline-secondary btn-sm rounded-pill"
                          onClick={() => {
                            console.log("prev button");
                            console.log(prev);
                            navigate(
                              `/post/${post.slug}`,
                              { state: { loc: location, prev: prev } },
                              { replace: true }
                            );
                          }}
                        >
                          {/* <Link to={`/post/${post.id}/${category}`} >Open</Link> */}
                          Open
                        </button>

                        <a
                          className="btn btn-outline-secondary btn-sm rounded-pill"
                          onClick={() => save(post.id)}
                        >
                          Save
                        </a>
                      </div>

                      {/* categories

                                                        <div className='loc d-flex text-muted' style={{ overflow: 'hidden' }}>
                                                            <h6>Categories: </h6>
                                                            {post.category[0].map((cat,index) => (
                                                                
                                                                index !== (post.category[0].length - 1) ?
                                                            <p key={cat} >{cat}, </p> 
                                                            :
                                                                <p key={cat} >{cat}</p>  
                                                                
                                                            )
                                                            )}
                                                        </div> */}
                    </div>

                    <hr />

                    <div className="card-f d-flex justify-content-between ">
                      <div className="card-text text-secondary">
                        <Moment fromNow>{post.date}</Moment>
                      </div>
                      <div className="text-muted">Status: Open</div>
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        ) : (
          ""
        )}
      </div>
      {loading && <img className="loader" src={Loader} alt="Loader" />}
    </>
  );
}

export default Test;
