import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { fetchAuth } from "./fetchAuth";

const resource = fetchAuth();

function Protected() {
  let usr = resource.ur.read();

  console.log("usr in protected");
  console.log(usr);

  const location = useLocation();

  return (
    <div>
      {localStorage.getItem("token") ? (
        usr === 3 || usr === 2 || usr === 1 ? (
          <Outlet />
        ) : usr === -1 ? (
          <Navigate to={"/ExpiredAcc"} />
        ) : (
          <Navigate to={"/login"} noThrow replace state={{ from: location }} />
        )
      ) : (
        <Navigate to={"/login"} noThrow replace state={{ from: location }} />
      )}
    </div>
  );
}

export default Protected;

// function Protected() {

//     const location = useLocation();

//     if(localStorage.getItem('token')){
//     const user = localStorage.getItem('AuthUser');
//     console.log(user)

//    if((user === '3') || (user === '2') || ( user === '1' ) ){

//     // localStorage.setItem('AuthUser', '7')
//     return <Outlet/>

//     } else if(user === '-1'){
//         // localStorage.setItem('AuthUser', '7')
//         return <Navigate to={ '/ExpiredAcc' } />

//     } else{
//         // localStorage.setItem('AuthUser', '7')
//         return <Navigate to={ '/login' } noThrow replace state={{from: location}} />

//              }
//             } else {
//                 return <Navigate to={ '/login' } noThrow replace state={{from: location}} />
//             }

// }

// export default Protected;
