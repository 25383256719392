import React from "react";
import UserDashboard from "./User/UserDashboard";
import AdSection from "./AdSection";

function Saved() {
  return (
    <UserDashboard>
      <div className="container my-4">
        <div className="row">
          {/* start of ad section */}
          <div className="col-lg-4 d-none d-lg-inline text-center">
            <AdSection />
          </div>
          {/* end of ad section */}

          <div className="col-lg-8">
            <div>Saved</div>
          </div>
        </div>
      </div>
    </UserDashboard>
  );
}

export default Saved;
