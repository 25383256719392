import React, { Component } from "react";

export class Sidebar extends Component {
  render() {
    return (
      <>
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">Search</h5>
          </div>
          <div className="card-body d-grid gap-2 ">
            <div className="">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
              ></input>
            </div>
            <div className="">
              <label htmlFor="inputCategories" className="form-label">
                Categories
              </label>
              <select id="inputCategories" className="form-select">
                <option selected>Choose...</option>
                <option>...</option>
              </select>
            </div>
            <div className="">
              <label htmlFor="inputLocation" className="form-label">
                Location
              </label>
              <select id="inputLocation" className="form-select">
                <option selected>Choose...</option>
                <option>...</option>
              </select>
            </div>
            <div className="">
              <label htmlFor="inputDate" className="form-label">
                Date
              </label>
              <select id="inputDate" className="form-select">
                <option selected>Choose...</option>
                <option>...</option>
              </select>
            </div>
            <button className="btn btn-outline-secondary btn-sm">Search</button>
          </div>
        </div>
      </>
    );
  }
}

export default Sidebar;
